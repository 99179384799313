
import firebase from "../src/firebase"



import "bootstrap/dist/css/bootstrap.min.css"
import './App.css';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from './pages/login';
import HomePage from './pages/HomePage';
import BeforeHomepage from "./pages/BeforeHomepage";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/BeforeHome"  element={<BeforeHomepage />}/>
        <Route path="/Home"  element={<HomePage />}/>
        <Route path="/"  element={<Login />}/>

      </Routes>
    </Router>
  );
}

export default App;
