
import React, { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate, Outlet } from "react-router-dom";
import app from '../firebase'
import {useLocation} from "react-router-dom";
import { getMessaging, getToken } from "firebase/messaging";

export default function BeforeHomepage() {
    const navigate = useNavigate();
    const location = useLocation();

// console.log(location.state.username)
    // useEffect(() => {

      
    //   }, []);
    return (
        <div>
            <Navbar bg="light" expand="lg">
            <Container>
              <Navbar.Brand href="#home">Welcome {location.state.username}</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  {/* <Nav.Link href="#home">Home</Nav.Link> */}
                  <Nav.Link onClick={ ()=>navigate('/')} href="#link">Log Out</Nav.Link>
                  {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.2">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action/3.4">
                      Separated link
                    </NavDropdown.Item>
                  </NavDropdown> */}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <div style={{margin:'1em'}}>
      <button onClick={()=>  navigate('/Home', { state: { username: location.state.username } })}  className="btn btn-primary">
        Call
      </button>
    </div>
    <div style={{margin:'1em'}}>
      <button   className="btn btn-primary">
        Button 2
      </button>
    </div>
    <div style={{margin:'1em'}}>
      <button   className="btn btn-primary">
      Button 3
      </button>
    </div>
    <div style={{margin:'1em'}}>
      <button   className="btn btn-primary">
      Button 4
      </button>
    </div>
    <div style={{margin:'1em'}}>
      <button   className="btn btn-primary">
      Button 5
      </button>
    </div>

    
      
        </div>
        )
}
