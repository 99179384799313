import React, { useState, useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router-dom'
import app from '../firebase'
import { useLocation } from 'react-router-dom'
import { getMessaging, getToken } from 'firebase/messaging'
import { DataGrid } from '@mui/x-data-grid'
import logo from '../Images/logo.png'
import Home from '../Images/home.png'
import logout from '../Images/logout.png'
import closeButton from '../Images/close.png'
import "./css/header.css"
import "./css/body.css"
import "./css/modal.css"
import Modal from 'react-modal';
import { get } from 'react-hook-form'
import axios from 'axios';

export default function HomePage() {
  const [showModal, setShowModal] = useState(false);
  const [showModalCheck, setShowModalCheck] = useState(false);
  const [showModalCheckUrgent, setShowModalCheckUrgent] = useState(false);
  const [showMessageSent, setShowMessageSent] = useState(false);
  const [showMessageCancelled, setShowMessageCancelled] = useState(false);
  const [showSelectOption, setShowSelectOption] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);

  const columns = [
    { field: 'firstName', headerName: 'First name', width: 130 },
    { field: 'lastName', headerName: 'Last name', width: 130 },
    { field: 'username', headerName: 'username', width: 180 },
    { field: 'Specialist', headerName: 'Specialist', width: 220 },
  ]

  const navigate = useNavigate()
  const location = useLocation()
  const [success, setsuccess] = useState(0)
  const [failure, setfailure] = useState(0)
  const [total, settotal] = useState(0)
  const [showresult, setshowresult] = useState(false)
  const [data, setData] = useState('')
  const [groupid, setGroupid] = useState([])
  const [group, setGroup] = useState([])
  const [rows, setRows] = useState([])
  const [Specialistid, setSpecialistid] = useState(null)
  const [Specialist, setSpecialist] = useState([])
  const [isgroup, setIsgroup] = useState(true)
  const [isspecialist, setIsspecialist] = useState(true)
  const [search, setSearch] = useState('')
  const [search1, setSearch1] = useState('')
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [showmodal, setshowmodal] = useState(false)
  const [showmodal1, setshowmodal1] = useState(true)
  const [showemergencybutton, setshowemergencybutton] = useState(false)
  const [checkboxSelection, setCheckboxSelection] = useState(true)

  const [fcmtoken, setFcmtokens] = useState([])
  const [allusersfcmtokens, setallusersFcmtokens] = useState([])
  const [allusers, setallusers] = useState([])
  const [usersid, setusersid] = useState([])
  const [username, setusername] = useState([])

  const [iosusers, setiosusers] = useState(0)
  const [androidusers, setandroiduser] = useState(0)

  // https://pager.osinode.com/auth/getusers/
  const [isguardusers, setisguardusers] = useState([])
  const [userbygroup, setUserbyGroup] = useState([])
  const [userbyspecialist, setUserbyspecialist] = useState([])


  useEffect(() => {
    setData(localStorage.getItem('dataKey'))
    getallgroup()
    getallSpecialist()
    firebaseftc()
    getallusers()
    if (search.length < 3) {
      console.log('null')
    } else {
      searchbyusername()
    }

    searchbyandroid()
    searchbyios()
  }, [rows, search, success])

  const myId = location.state.userid;

  //FETCHING THE LATEST SENT NOTIFICATION ROW 
  const fetchLatestNotification = async () => {
    try {
      const res = await axios.get(`https://pager.osinode.com/auth/notifications/${myId}/`);
      const lastNot = res.data;

      // Accessing specific columns
      const notTitle = lastNot.Notification_Tile;
      const notId = lastNot.Notification_id;
      const userr_name = lastNot.user_name;

      // Return all the columns
      return {
        notTitle,
        notId,
      };
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  // FETCHING REPLIES
  const fetchReplies = async () => {
    try {
      const res = await axios.get(`https://pager.osinode.com/auth/replies/user/${myId}/`);
      const replies = res.data;

      const dynamicReplies = document.getElementById('dynamicReplies');
      dynamicReplies.innerHTML = ''; //Clear existing replies

      const latestReplies = replies.slice(-5); //Get the latest 5 replies

      //Loop over the latest replies in reverse order
      for (let i = latestReplies.length - 1; i >= 0; i--) {
        const reply = latestReplies[i];

        //Accessing specific columns
        const replier = reply.user.username;
        const replyMssg = reply.message;
        const timeString = reply.time;

        //Create a new Date object using the time string
        const date = new Date(timeString);

        //Format the date in a readable format
        const formattedTime = date.toLocaleString();

        //Create reply container
        const replyContainer = document.createElement('div');
        replyContainer.className = 'reply-mssg';

        //Create replier element
        const replierElement = document.createElement('h1');
        replierElement.textContent = replier;
        replyContainer.appendChild(replierElement);

        //Create time element
        const timeElement = document.createElement('h3');
        timeElement.textContent = formattedTime;
        replyContainer.appendChild(timeElement);

        //Create message element
        const messageElement = document.createElement('p');
        messageElement.textContent = replyMssg;
        replyContainer.appendChild(messageElement);

        //Append reply container to the dynamic replies section
        dynamicReplies.appendChild(replyContainer);
      }
    } catch (error) {
      console.error('Error fetching replies:', error);
    }
  };

  useEffect(() => {
    fetchLatestNotification();
    fetchReplies();
  }, []);

  //TO REFRESH THE REPLIES CONTAINER EVERY 15 SECONDS
  const refreshReplies = () => {
    setInterval(fetchReplies, 15000); // Refresh every 15 seconds
  };

  refreshReplies();

  const searchbyandroid = async x => {
    const response = await fetch(
      'https://pager.osinode.com/auth/userbyplatform/?search=android',
      {
        headers: {
          //'Accept': 'application/json',
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }
    )
      .then(response => response.json())
      .then(data => {
        setandroiduser(data.count)
      })
      .catch(error => {
        console.log(error)
      })
  }
  const searchbyios = async x => {
    const response = await fetch(
      'https://pager.osinode.com/auth/userbyplatform/?search=ios',
      {
        headers: {
          //'Accept': 'application/json',
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }
    )
      .then(response => response.json())
      .then(data => {
        setiosusers(data.count)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const firebaseftc = () => {
    const messaging = getMessaging()
    getToken(messaging, {
      vapidKey:
        'BKttnx4foBmrfGOgaixuM_IwLPgonmeHSL8WyAf3cTj20v69uB4Id3S5wEPbPF4lxopD6qhFrjG_zXSWUjyKij8'
    })
      .then(currentToken => {
        if (currentToken) {
        } else {
          // Show permission request UI
          console.log(
            'No registration token available. Request permission to generate one.'
          )
          // ...
        }
      })
      .catch(err => {
        console.log('An error occurred while retrieving token. ', err)
        // ...
      })
  }

  const getallusers = async () => {
    const response = await fetch('https://pager.osinode.com/auth/getusers/')
      .then(response => response.json())
      .then(data => {
        let x = []

        for (let i = 0; i < data.length; i++) {
          allusers.push(data[i].id)
          if (data[i].fcmtoken == null) {
            console.log('null')
          } else {
            allusersfcmtokens.push(data[i].fcmtoken)
          }
        }
        setshowemergencybutton(true)
      })
      .catch(error => { })

    // update the state
  }

  const getusersisguard1 = async () => {
    const response = await fetch('https://pager.osinode.com/auth/userisguard/')
      .then(response => response.json())
      .then(data => {
        let x = []

        for (let i = 0; i < data.length; i++) {
          usersid.push(data[i].id)
          console.log(data[i])
          username.push(data[i].username)
          if (data[i].fcmtoken == null) {
            console.log(null)
          } else {
            fcmtoken.push(data[i].fcmtoken)
          }
        }

        multipleCodeblue()
      })
      .catch(error => { })

    // update the state
  }

  const getusersisguard = async () => {
    const response = await fetch('https://pager.osinode.com/auth/userisguard/')
      .then(response => response.json())
      .then(data => {
        let x = []

        for (let i = 0; i < data.length; i++) {
          usersid.push(data[i].id)
          console.log(data[i])
          username.push(data[i].username)
          if (data[i].fcmtoken == null) {
            console.log(null)
          } else {
            fcmtoken.push(data[i].fcmtoken)
          }
        }

        multipleRRT()
      })
      .catch(error => { })

    // update the state
  }

  const getusersbygroup = async id => {
    const response = await fetch(
      'https://pager.osinode.com/auth/userbygroup/' + id
    )
      .then(response => response.json())
      .then(data => {
        let x = []
        console.log(data)
        for (let i = 0; i < data.length; i++) {
          // fcmtoken.push(data[i].fcmtoken)

          if (data[i].is_active) {
            if (data[i].SpecialistID == null) {
              console.log(data[i].id)
              var obj = {}
              obj['id'] = data[i].id
              obj['firstName'] = data[i].first_name
              obj['username'] = data[i].username
              obj['lastName'] = data[i].last_name
              obj['Mobile'] = data[i].Mobile
              obj['Extension'] = data[i].Extension
              obj['fcmtoken'] = data[i].fcmtoken
              obj['Specialist'] = '-'
              x.push(obj)
            } else {
              console.log(data[i].id)
              var obj = {}
              obj['id'] = data[i].id
              obj['firstName'] = data[i].first_name
              obj['username'] = data[i].username
              obj['lastName'] = data[i].last_name
              obj['Mobile'] = data[i].Mobile
              obj['Extension'] = data[i].Extension
              obj['fcmtoken'] = data[i].fcmtoken
              obj['Specialist'] = data[i].SpecialistID.Specialist_name
              x.push(obj)
            }
          } else {
            console.log('not active')
          }
        }

        setRows(x)
        setUserbyGroup(data)
        setIsgroup(true)
        setIsspecialist(true)
      })
      .catch(error => { })

    // update the state
  }

  //GET USERS BY GROUP WITHOUT DISPLAYING THEM ON THE SCREEN IN A TABLE
  const getUsersByGroup = async id => {
    try {
      const response = await fetch('https://pager.osinode.com/auth/userbygroup/' + id);
      const data = await response.json();

      let users = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].is_active) {
          const user = {
            id: data[i].id,
            firstName: data[i].first_name,
            username: data[i].username,
            lastName: data[i].last_name,
            Mobile: data[i].Mobile,
            Extension: data[i].Extension,
            fcmtoken: data[i].fcmtoken,
            Specialist: data[i].SpecialistID ? data[i].SpecialistID.Specialist_name : '-'
          };
          users.push(user);
        }
      }
      // console.log("Fetched Users:", users);
      return users; // Return the fetched users
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getusersbyspecialist = async id => {
    const response = await fetch(
      'https://pager.osinode.com/auth/userbySpecialist/' + id
    )
      .then(response => response.json())
      .then(data => {
        let x = []

        for (let i = 0; i < data.length; i++) {
          // fcmtoken.push(data[i].fcmtoken)
          if (data[i].is_active) {
            if (data[i].SpecialistID == null) {
              console.log(data[i].id)
              var obj = {}
              obj['id'] = data[i].id
              obj['firstName'] = data[i].first_name
              obj['username'] = data[i].username
              obj['lastName'] = data[i].last_name
              obj['Mobile'] = data[i].Mobile
              obj['Extension'] = data[i].Extension
              obj['fcmtoken'] = data[i].fcmtoken
              obj['Specialist'] = '-'
              x.push(obj)
            } else {
              console.log(data[i].id)
              var obj = {}
              obj['id'] = data[i].id
              obj['firstName'] = data[i].first_name
              obj['username'] = data[i].username
              obj['lastName'] = data[i].last_name
              obj['Mobile'] = data[i].Mobile
              obj['Extension'] = data[i].Extension
              obj['fcmtoken'] = data[i].fcmtoken
              obj['Specialist'] = data[i].SpecialistID.Specialist_name
              x.push(obj)
            }
          } else {
            console.log('not active')
          }
        }

        setRows(x)
        setUserbyspecialist(data)
        setIsgroup(true)
      })
      .catch(error => { })

    // update the state
  }

  const getallgroup = async () => {
    const response = await fetch('https://pager.osinode.com/auth/group/')
      .then(response => response.json())
      .then(data => {
        setGroup(data)
      })
      .catch(error => { })

    // update the state
  }

  const getallSpecialist = async () => {
    const response = await fetch('https://pager.osinode.com/auth/specialist/')
      .then(response => response.json())
      .then(data => {
        setSpecialist(data)
      })
      .catch(error => { })

    // update the state
  }

  //Fetching the ids of the selected groups and its users
  const users = [];
  const handleGroupChange = async event => {
    const clickedOption = event.target.value;
    const isChecked = event.target.checked;

    let updatedOptions;
    if (isChecked) {
      updatedOptions = [...groupid, clickedOption];
    } else {
      updatedOptions = groupid.filter(option => option !== clickedOption);
    }

    setGroupid(updatedOptions);
    setSpecialistid(null);

    const selectedGroups = group
      .filter(groupItem => updatedOptions.includes(groupItem.Group_id.toString()))
      .map(groupItem => groupItem.Group_id);


    for (let i = 0; i < selectedGroups.length; i++) {
      const group = selectedGroups[i];
      const groupUsers = await getUsersByGroup(group);
      users.push(...groupUsers);
    }

    console.log("Selected Groups:", selectedGroups);
    console.log("Fetched Users:", users);
  };

  const handleChange = event => {
    setGroupid(event.target.value)
    console.log(event.target.label)
    setshowmodal1(false)
    setSpecialistid(null)
    getusersbygroup(event.target.value)
  }
  const handleChange1 = event => {
    setSpecialistid(event.target.value)
    setshowmodal1(false)
    setGroupid(null)
    getusersbyspecialist(event.target.value)
  }
  const showgroup = () => {
    setIsgroup(true)
    setUserbyspecialist([])

    setIsspecialist(true)
  }
  const showspecialist = () => {
    setIsgroup(true)
    setUserbyGroup([])

    setIsspecialist(true)
  }

  //CALL NOTIFICATION
  //Show a pop up modal when clicking on the call button for confirmation
  const addpushnotificationcallProceed = async () => {
    setShowModalCheck(true);
  }

  //Pushing the notification to the database
  const addpushnotificationcall = async (x, y) => {
    var today = new Date(),
      date =
        today.getDate() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getFullYear(),
      time = today.getHours() + ':' + today.getMinutes()
    console.log(
      JSON.stringify({
        Notification_Tile: 'Call',
        Notification_Body:
          location.state.username +
          ' Called you at ' +
          date +
          ' on ' +
          time +
          ' .Kindly call us on ' +
          location.state.ext,
        Group_ID: groupid,
        Specialist_ID: Specialistid,
        User: Number(location.state.userid),
        Users_id: JSON.stringify(usersid),
        Users_name: JSON.stringify(username),
        is_emergency: false,
        Success: x,
        Failure: y
      })
    )
    const response = await fetch(
      'https://pager.osinode.com/auth/notifications/add',
      {
        method: 'POST',
        headers: {
          //'Accept': 'application/json',
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          Notification_Tile: 'Call',
          Notification_Body:
            location.state.username +
            ' Called you at ' +
            date +
            ' on ' +
            time +
            ' .Kindly call us on ' +
            location.state.ext,
          Group_ID: groupid,
          Specialist_ID: Specialistid,
          User: Number(location.state.userid),
          Users_id: JSON.stringify(usersid),
          Users_name: JSON.stringify(username),
          Success: x,
          Failure: y
        })
      }
    )
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setusersid([])
        setUserbyGroup([])
        setUserbyspecialist([])
        setshowmodal1(!showmodal1)
      })
      .catch(error => {
        console.log(error)
      })
  }

  //Sending the notification
  const senpushnotificationcall = async () => {
    const { notId } = await fetchLatestNotification();
    const { notTitle } = await fetchLatestNotification();
    var today = new Date(),
      date =
        today.getDate() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getFullYear(),
      time = today.getHours() + ':' + today.getMinutes()
    let x = []
    for (let i = 0; i < fcmtoken.length; i++) {
      usersid.push(fcmtoken[i].id)
      console.log(fcmtoken[i].username)
      username.push(fcmtoken[i].username)
      if (fcmtoken[i].fcmtoken == null) {
        console.log('null')
      } else {
        x.push(fcmtoken[i].fcmtoken)
      }
    }

    console.log(
      JSON.stringify({
        registration_ids: x,
        priority: 'high',
        notification: {
          title: 'Call',
          body:
            location.state.username +
            ' Called you at ' +
            date +
            ' on ' +
            time +
            ' .Kindly call us on ' +
            location.state.ext,
          content_available: true,
          sound: 'siren.mp3',
          android_channel_id: 'notificationname'
        },
        content_available: true,

        android: {
          priority: 'high'
        },
        data: {
          type: '0',
          title: 'this is test 56565',
          body: 'works',
          timestamp: '627E1D36',
          source: 'postman',
          sound: 'default',
          vibration: '150',
        },
        'apns-push-type': 'alert',
        'apns-expiration': 0,
        content_available: true,
        'apns-priority': 5,
        aps: {
          'content-available': 1
        }
      })
    )
    const response = await fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization:
          'key=AAAAmGWLKP0:APA91bGXbXo_KQMxHR1YbTGz_iQalSe-TtR3aOcOy6XNaqOZlF6DF2gweC2E0b03qlGf6GLg1A44W_ipKnYnqiV1_78_4ZToN_TH2ARYZ5QXKfJa6-yu_z1vWp5B3FFQMvsZ09r2NlqL'
      },
      body: JSON.stringify({
        registration_ids: x,
        priority: 'high',
        notification: {
          notId : notId,
          title: notTitle,
          body:
            'Notification ID:' + notId + '\n' +
            'You received a notification from ' + location.state.username + '\n' +
            'At ' +
            date +
            ' on ' +
            time +
            '. Kindly call us on ' +
            location.state.ext,
          content_available: true,
          sound: 'siren.mp3',
          android_channel_id: 'notificationname'
        },
        content_available: true,

        android: {
          priority: 'high'
        },
        data: {
          type: '0',
          title: 'this is test 56565',
          body: 'works',
          timestamp: '627E1D36',
          source: 'postman',
          sound: 'default',
          vibration: '150'
        },
        'apns-push-type': 'alert',
        'apns-expiration': 0,
        content_available: true,
        'apns-priority': 5,
        aps: {
          'content-available': 1
        }
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setsuccess(data.success)
        setfailure(data.failure)
        settotal(data.success + data.failure)
        setshowresult(true)
        addpushnotificationcall(data.success, data.failure)
      })
      .catch(error => {
        console.log(error)
      })
  }
  //CALL NOTIFICATION END


  //CALL URGENT NOTIFICATION
  //Show a pop up modal when clicking on the call urgent button for confirmation
  const addpushnotificationcallurgentProceed = async () => {
    setShowModalCheckUrgent(true);
  }

  //Pushing the notification to the database
  const addpushnotificationcallurgent = async (x, y) => {
    var today = new Date(),
      date =
        today.getDate() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getFullYear(),
      time = today.getHours() + ':' + today.getMinutes()
    const response = await fetch(
      'https://pager.osinode.com/auth/AddNotification/',
      {
        method: 'POST',
        headers: {
          //'Accept': 'application/json',
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          Notification_Tile: 'Call',
          Notification_Body:
            location.state.username +
            ' Called you URGENTLY  at ' +
            date +
            ' on ' +
            time +
            ' .Kindly call us on ' +
            location.state.ext,
          Group_ID: groupid,
          Specialist_ID: Specialistid,
          User: Number(location.state.userid),
          Users_id: JSON.stringify(usersid),
          Users_name: JSON.stringify(username),
          Success: x,
          Failure: y
        })
      }
    )
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setUserbyGroup([])
        setusersid([])
        setUserbyspecialist([])
        setshowmodal1(!showmodal1)
      })
      .catch(error => {
        console.log(error)
      })
  }

  //Sending the notification
  const senpushnotificationcallurgent = async () => {
    const { notId } = await fetchLatestNotification();
    const { notTitle } = await fetchLatestNotification();
    var today = new Date(),
      date =
        today.getDate() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getFullYear(),
      time = today.getHours() + ':' + today.getMinutes()
    let x = []
    for (let i = 0; i < fcmtoken.length; i++) {
      usersid.push(fcmtoken[i].id)
      console.log(fcmtoken[i].username)
      username.push(fcmtoken[i].username)
      if (fcmtoken[i].fcmtoken == null) {
        console.log('null')
      } else {
        x.push(fcmtoken[i].fcmtoken)
      }
    }

    console.log(
      JSON.stringify({
        registration_ids: x,
        notification: {
          title: 'Call',
          body:
            location.state.username +
            ' Called you URGENTLY  at ' +
            date +
            ' on ' +
            time +
            ' .Kindly call us on ' +
            location.state.ext,
          sound: 'siren.mp3',
          android_channel_id: 'notificationname'
        },
        content_available: true,

        android: {
          priority: 'high'
        },
        apns: {
          payload: {
            aps: {
              contentAvailable: 1
            }
          }
        },
        priority: 'high'
      })
    )
    const response = await fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        //'Accept': 'application/json',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization:
          'key=AAAAmGWLKP0:APA91bGXbXo_KQMxHR1YbTGz_iQalSe-TtR3aOcOy6XNaqOZlF6DF2gweC2E0b03qlGf6GLg1A44W_ipKnYnqiV1_78_4ZToN_TH2ARYZ5QXKfJa6-yu_z1vWp5B3FFQMvsZ09r2NlqL'
      },
      body: JSON.stringify({
        registration_ids: x,
        priority: 'high',
        notification: {
          notId: notId,
          title: 'Call',
          body:
            'Notification ID:' + notId + '\n' +
            'You received an URGENT call from ' + location.state.username + '\n' +
            'At ' +
            date +
            ' on ' +
            time +
            '. Kindly call us on ' +
            location.state.ext,
          content_available: true,
          sound: 'siren.mp3',
          android_channel_id: 'notificationname'
        },
        content_available: true,

        android: {
          priority: 'high'
        },
        data: {
          type: '0',
          title: 'this is test 56565',
          body: 'works',
          timestamp: '627E1D36',
          source: 'postman',
          sound: 'default',
          vibration: '150'
        },
        'apns-push-type': 'alert',
        'apns-expiration': 0,
        content_available: true,
        'apns-priority': 5,
        aps: {
          'content-available': 1
        }
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setsuccess(data.success)
        setfailure(data.failure)
        settotal(data.success + data.failure)
        setshowresult(true)
        addpushnotificationcallurgent(data.success, data.failure)
      })
      .catch(error => {
        console.log(error)
      })
  }
  //CALL URGENT NOTIFICATION END

  const senpushnotificationcallRRT = async () => {
    var today = new Date(),
      date =
        today.getDate() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getFullYear(),
      time = today.getHours() + ':' + today.getMinutes()
    let x = []
    // for (let i = 0; i < fcmtoken.length; i++) {
    //   if (fcmtoken[i].fcmtoken == null) {
    //     console.log('null')
    //   } else {
    //     x.push(fcmtoken[i].fcmtoken)
    //   }
    // }

    console.log(
      JSON.stringify({
        registration_ids: fcmtoken,
        notification: {
          title: '* * * RRT CALL* * *',
          body:
            location.state.username +
            ' - ' +
            location.state.ext +
            ' - ' +
            date +
            ' on ' +
            time,
          sound: 'siren.mp3',
          android_channel_id: 'notificationname'
        }
      })
    )
    const response = await fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        //'Accept': 'application/json',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization:
          'key=AAAAmGWLKP0:APA91bGXbXo_KQMxHR1YbTGz_iQalSe-TtR3aOcOy6XNaqOZlF6DF2gweC2E0b03qlGf6GLg1A44W_ipKnYnqiV1_78_4ZToN_TH2ARYZ5QXKfJa6-yu_z1vWp5B3FFQMvsZ09r2NlqL'
      },
      body: JSON.stringify({
        registration_ids: fcmtoken,
        notification: {
          title: '* * * RRT CALL* * *',
          body:
            location.state.username +
            ' - ' +
            location.state.ext +
            ' - ' +
            date +
            ' on ' +
            time,
          sound: 'siren.mp3',
          android_channel_id: 'notificationname'
        },
        content_available: true,

        android: {
          priority: 'high'
        },
        apns: {
          payload: {
            aps: {
              contentAvailable: 1
            }
          }
        },
        priority: 'high'
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setsuccess(data.success)
        setfailure(data.failure)
        settotal(data.success + data.failure)
        setshowresult(true)
        addpushnotificationcallRRT(data.success, data.failure)
      })
      .catch(error => {
        console.log(error)
      })
  }
  const addpushnotificationcallRRT = async (x, y) => {
    var today = new Date(),
      date =
        today.getDate() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getFullYear(),
      time = today.getHours() + ':' + today.getMinutes()
    const response = await fetch(
      'https://pager.osinode.com/auth/AddNotification/',
      {
        method: 'POST',
        headers: {
          //'Accept': 'application/json',
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          Notification_Tile: '* * * RRT CALL* * *',
          Notification_Body:
            location.state.username +
            ' - ' +
            location.state.ext +
            ' - ' +
            date +
            ' on ' +
            time,
          Group_ID: groupid,
          Specialist_ID: Specialistid,
          User: Number(location.state.userid),
          Users_id: JSON.stringify(usersid),
          Users_name: JSON.stringify(username),
          Success: x,
          Failure: y
        })
        //  body: {
        //    "Notification_Tile": title,
        //   //"Notification_Body": body,
        //   //"Group_ID": groupid,
        //   //"Specialist_ID": Specialistid
        // }
      }
    )
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setusersid([])
        setUserbyGroup([])
        setUserbyspecialist([])
        setshowmodal1(!showmodal1)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const senpushnotificationcallCodeBlue = async () => {
    var today = new Date(),
      date =
        today.getDate() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getFullYear(),
      time = today.getHours() + ':' + today.getMinutes()
    let x = []

    console.log(
      JSON.stringify({
        registration_ids: fcmtoken,
        notification: {
          title: '* * * CODE BLUE* * *',
          body:
            location.state.username +
            ' - ' +
            location.state.ext +
            ' - ' +
            date +
            ' on ' +
            time,
          sound: 'siren.mp3',
          android_channel_id: 'notificationname'
        }
      })
    )
    const response = await fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        //'Accept': 'application/json',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization:
          'key=AAAAmGWLKP0:APA91bGXbXo_KQMxHR1YbTGz_iQalSe-TtR3aOcOy6XNaqOZlF6DF2gweC2E0b03qlGf6GLg1A44W_ipKnYnqiV1_78_4ZToN_TH2ARYZ5QXKfJa6-yu_z1vWp5B3FFQMvsZ09r2NlqL'
      },
      body: JSON.stringify({
        registration_ids: fcmtoken,
        notification: {
          title: '* * * CODE BLUE* * *',
          body:
            location.state.username +
            ' - ' +
            location.state.ext +
            ' - ' +
            date +
            ' on ' +
            time,
          sound: 'siren.mp3',
          android_channel_id: 'notificationname'
        },
        content_available: true,

        android: {
          priority: 'high'
        },
        apns: {
          payload: {
            aps: {
              contentAvailable: 1
            }
          }
        },
        priority: 'high'
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setsuccess(data.success)
        setfailure(data.failure)
        settotal(data.success + data.failure)
        setshowresult(true)
        addpushnotificationcallCodeBlue(data.success, data.failure)
      })
      .catch(error => {
        console.log(error)
      })
  }
  const addpushnotificationcallCodeBlue = async (x, y) => {
    var today = new Date(),
      date =
        today.getDate() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getFullYear(),
      time = today.getHours() + ':' + today.getMinutes()
    const response = await fetch(
      'https://pager.osinode.com/auth/AddNotification/',
      {
        method: 'POST',
        headers: {
          //'Accept': 'application/json',
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          Notification_Tile: '* * * CODE BLUE* * *',
          Notification_Body:
            location.state.username +
            ' - ' +
            location.state.ext +
            ' - ' +
            date +
            ' on ' +
            time,
          Group_ID: groupid,
          Specialist_ID: Specialistid,
          User: Number(location.state.userid),
          Users_id: JSON.stringify(usersid),
          Users_name: JSON.stringify(username),
          Success: x,
          Failure: y
        })
        //  body: {
        //    "Notification_Tile": title,
        //   //"Notification_Body": body,
        //   //"Group_ID": groupid,
        //   //"Specialist_ID": Specialistid
        // }
      }
    )
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setusersid([])
        setUserbyGroup([])
        setUserbyspecialist([])
        setshowmodal1(!showmodal1)
      })
      .catch(error => {
        console.log(error)
      })
  }

  //EMERGENCY BROADCAST FOR ALL USERS
  //Sending the push notification
  const senpushnotificationcallemergency = async () => {
    var today = new Date(),
      date =
        today.getDate() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getFullYear(),
      time = today.getHours() + ':' + today.getMinutes()
    let x = []

    console.log(
      JSON.stringify({
        registration_ids: allusersfcmtokens,
        notification: {
          title: title,
          body: body,
          sound: 'siren.mp3',
          android_channel_id: 'notificationname'
        }
      })
    )
    const response = await fetch('https://fcm.googleapis.com/fcm/send', {
      method: 'POST',
      headers: {
        //'Accept': 'application/json',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization:
          'key=AAAAmGWLKP0:APA91bGXbXo_KQMxHR1YbTGz_iQalSe-TtR3aOcOy6XNaqOZlF6DF2gweC2E0b03qlGf6GLg1A44W_ipKnYnqiV1_78_4ZToN_TH2ARYZ5QXKfJa6-yu_z1vWp5B3FFQMvsZ09r2NlqL'
      },

      body: JSON.stringify({
        registration_ids: allusersfcmtokens,
        priority: 'high',
        notification: {
          title: title,
          body: body,
          content_available: true,
          sound: 'siren.mp3',
          android_channel_id: 'notificationname'
        },
        content_available: true,

        android: {
          priority: 'high'
        },
        data: {
          type: '0',
          title: 'this is test 56565',
          body: 'works',
          timestamp: '627E1D36',
          source: 'postman',
          sound: 'default',
          vibration: '150'
        },
        'apns-push-type': 'alert',
        'apns-expiration': 0,
        content_available: true,
        'apns-priority': 5,
        aps: {
          'content-available': 1
        }
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setsuccess(data.success)
        setfailure(data.failure)
        settotal(data.success + data.failure)
        setShowModal(true);
        addpushnotificationcallemergency(data.success, data.failure)
      })
      .catch(error => {
        console.log(error)
      })
  }

  //Pushing the notification to the database
  const addpushnotificationcallemergency = async (x, y) => {
    var today = new Date(),
      date =
        today.getDate() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getFullYear(),
      time = today.getHours() + ':' + today.getMinutes()
    const response = await fetch(
      'https://pager.osinode.com/auth/notifications/add',
      {
        method: 'POST',
        headers: {
          //'Accept': 'application/json',
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          Notification_Tile: title,
          Notification_Body: body,
          Group_ID: groupid,
          Specialist_ID: Specialistid,
          User: Number(location.state.userid),
          Users_id: JSON.stringify(allusers),
          Users_name: JSON.stringify(username),
          Success: x,
          Failure: y,
          is_emergency: true
        })
      }
    )
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setusersid([])
        window.location.reload(false)
      })
      .catch(error => {
        console.log(error)
      })
  }
  //EMERGENCY BROADCAST TO ALL USERS END


  //EMERGENCY BROADCAST TO SELECTED GROUP/GROUPS
  //Show a pop up modal when clicking on the Emergency Broadcast to select recipients
  const sendPushEmergencySelectedrecipients = async () => {
    setShowSelectOption(true);
  }

  //Sending the push notification to selected recipients
  const sendpushnotificationselectecipients = async () => {
    // Retrieve users by group
    const selectedGroupIds = groupid.map(id => parseInt(id));
    const users = [];
    for (let i = 0; i < selectedGroupIds.length; i++) {
      const groupUsers = await getUsersByGroup(selectedGroupIds[i]);
      users.push(...groupUsers);
    }

    // Populate the 'x' array with fcmtoken values
    const fcmtokens = users.map(user => user.fcmtoken);
    const x = [...fcmtokens];

    const payload = {
      registration_ids: x,
      priority: 'high',
      notification: {
        title: title,
        body: body,
        sound: 'siren.mp3',
        android_channel_id: 'notificationname'
      },
      content_available: true,
      data: {
        type: '0',
        title: 'this is test 56565',
        body: 'works',
        timestamp: '627E1D36',
        source: 'postman',
        sound: 'default',
        vibration: '150'
      },
      'apns-push-type': 'alert',
      'apns-expiration': 0,
      'apns-priority': 5,
      aps: {
        'content-available': 1
      }
    };

    console.log("Notification Payload:", JSON.stringify(payload));

    try {
      const response = await fetch('https://fcm.googleapis.com/fcm/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization:
            'key=AAAAmGWLKP0:APA91bGXbXo_KQMxHR1YbTGz_iQalSe-TtR3aOcOy6XNaqOZlF6DF2gweC2E0b03qlGf6GLg1A44W_ipKnYnqiV1_78_4ZToN_TH2ARYZ5QXKfJa6-yu_z1vWp5B3FFQMvsZ09r2NlqL'
        },
        body: JSON.stringify(payload)
      });

      const data = await response.json();
      console.log(data);

      setsuccess(data.success);
      setfailure(data.failure);
      settotal(data.success + data.failure);
      setShowModal(true);
      addpushnotificationselectecipients(data.success, data.failure);
    } catch (error) {
      console.log(error);
    }
  };

  //Pushing the notification to the database
  const addpushnotificationselectecipients = async (x, y, selectedGroups) => {
    var today = new Date();
    var date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
    var time = today.getHours() + ':' + today.getMinutes();

    try {
      const response = await fetch('https://pager.osinode.com/auth/notifications/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          Notification_Tile: title,
          Notification_Body: body,
          Group_ID: JSON.stringify(selectedGroups),
          Specialist_ID: Specialistid,
          User: Number(location.state.userid),
          Users_id: null,
          Users_name: null,
          Success: x,
          Failure: y,
          is_emergency: true,
        }),
      });

      const data = await response.json();
      console.log(data);
      setusersid([]);
      setSelectedGroups([]);
      console.log(selectedGroups);
    } catch (error) {
      console.log(error);
    }
  };
  //EMERGENCY BROADCAST TO SELECTED RECIPIENTS END

  const searchbyplatform = async x => {
    console.log('https://pager.osinode.com/auth/userbyplatform/?search=' + x)
    const response = await fetch(
      'https://pager.osinode.com/auth/userbyplatform/?search=' + x,
      {
        headers: {
          //'Accept': 'application/json',
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }
    )
      .then(response => response.json())
      .then(data => {
        setUserbyGroup(data.results)
        setshowmodal1(!showmodal1)
        let x = []
        for (let i = 0; i < data.results.length; i++) {
          if (data.results[i].is_active) {
            var obj = {}
            obj['id'] = data.results[i].id
            obj['firstName'] = data.results[i].first_name
            obj['username'] = data.results[i].username
            obj['lastName'] = data.results[i].last_name
            obj['Mobile'] = data.results[i].Mobile
            obj['Extension'] = data.results[i].Extension
            obj['fcmtoken'] = data.results[i].fcmtoken
            // obj['Specialist'] = data.results[i].SpecialistID.Specialist_name
            x.push(obj)
          } else {
            console.log('not active')
          }
        }

        setRows(x)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const searchbyusername = async () => {
    const response = await fetch(
      'https://pager.osinode.com/auth/userbyusername/?search=' + search,
      {
        headers: {
          //'Accept': 'application/json',
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }
    )
      .then(response => response.json())
      .then(data => {
        let x = []
        for (let i = 0; i < data.results.length; i++) {
          if (data.results[i].is_active) {
            if (data.results[i].GroupID.Group_id != groupid) {
              console.log('null')
            }
            if (data.results[i].GroupID.Group_id == groupid) {
              var obj = {}
              obj['id'] = data.results[i].id
              obj['firstName'] = data.results[i].first_name
              obj['username'] = data.results[i].username
              obj['lastName'] = data.results[i].last_name
              obj['Mobile'] = data.results[i].Mobile
              obj['Extension'] = data.results[i].Extension
              obj['fcmtoken'] = data.results[i].fcmtoken
              obj['Specialist'] = data.results[i].SpecialistID.Specialist_name
              x.push(obj)
            }
          } else {
            console.log('not active')
          }
        }

        setRows(x)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const multiple = async () => {
    addpushnotificationcallProceed()
    setTitle('')
    setBody('')
  }
  const multipleurgent = async () => {
    addpushnotificationcallurgentProceed()
    setTitle('')
    setBody('')
  }
  const multipleRRT = async () => {
    senpushnotificationcallRRT()
    setTitle('')
    setBody('')
  }

  const multipleCodeblue = async () => {
    senpushnotificationcallCodeBlue()
    setTitle('')
    setBody('')
  }

  const multiple1 = async () => {
    searchbyusername()
  }

  const multiple3 = async () => {
    if (title == '') {
      setshowmodal1(true)
      setUserbyGroup([])
      setUserbyspecialist([])
    } else {
      senpushnotificationcallemergency()
      setTitle('')
      setBody('')
      setshowmodal1(false)
    }
  }


  return (
    <div class='d-flex flex-column'>
      <header class="navigation-bar">
        <nav class="inner-navigation-bar">
          <div class="logo-container">
            <img src={logo} alt="Logo" class="logo" onClick={() => window.location.reload(false)} />
          </div>
          <div class="menu-container">
            <img src={Home} alt="Home" onClick={() => window.location.reload(false)} />
            <img src={logout} alt="Logout" onClick={() => {
              localStorage.setItem('isloggedin', 'false')
              navigate('/')
            }} />
          </div>
        </nav>
      </header>

      {/* WELCOME BAR */}
      <div class="welcome-bar">
        <h1>
          {' '}
          Welcome {location.state.username} your Ext Number is :{' '}
          {location.state.ext}
        </h1>
      </div>

      {/* IOS AND ANDROID USERS */}
      <div class="android-ios-count">
        <div onClick={() => {
          searchbyplatform('android')
        }}>
          <h3>Android Users: {androidusers}</h3>
        </div>
        <div onClick={() => {
          searchbyplatform('ios')
        }}>
          <h3>IOS Users:  {iosusers}</h3>
        </div>
      </div>

      {/* BODY SECTION */}
      {/* Groups and Specialist section */}
      <div className="main">
        <div className="main-sections-container">
          <div className="sections-container">
            {/* Groups */}
            <div class="grp-sect-container" onClick={() => {
              showgroup()
            }}>
              <h1>Select By Group</h1>
              {isgroup ? (
                <div>
                  <select
                    value={group}
                    onChange={handleChange}
                    class="select-tag"
                  >
                    <option>Groups</option>
                    {group.map(option => (
                      <option value={option.Group_id}>{option.Group_name}</option>
                    ))}
                  </select>
                </div>
              ) : null}
            </div>

            {/* Specialist */}
            <div class="grp-sect-container" onClick={() => {
              showspecialist()
            }}>
              <h1>Select By Specialist</h1>
              {isspecialist ? (
                <div>
                  <select
                    value={Specialist}
                    onChange={handleChange1}
                    class="select-tag"
                  >
                    <option>Specialist</option>
                    {Specialist.map(option => (
                      <option value={option.Specialist_id}>
                        {option.Specialist_name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
            </div>
          </div>

          {/* FILTERING WHEN SELECTING A GROUP OR SPECIALIST */}
          <div>
            <div className='filtering-section'>
              {userbyspecialist.length == 0 ? null : (
                <div>
                  <div>
                    <input
                      type='Search'
                      className='filtering-search-tag'
                      placeholder='Search By Username And Specialist'
                      autoFocus
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      height:
                        rows.length > 20
                          ? 950
                          : rows.length > 8
                            ? 400
                            : rows.length > 5
                              ? 250
                              : 200,
                    }}
                  >
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pageSize={20}
                      rowsPerPageOptions={[10]}
                      checkboxSelection={checkboxSelection}
                      onSelectionModelChange={ids => {
                        const selectedIDs = new Set(ids)
                        const selectedRowData = rows.filter(row =>
                          selectedIDs.has(row.id)
                        )
                        let x = []

                        setFcmtokens(selectedRowData)

                        for (let i = 0; i < selectedRowData.length; i++) {
                          if (selectedRowData[i].fcmtoken == null) {
                            console.log('nill')
                          } else {
                            x.push(selectedRowData[i].fcmtoken)
                          }
                        }

                        if (x.length == 0) {
                          setshowmodal(false)
                        } else {
                          setshowmodal(true)
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='filtering-section'>
              {userbygroup.length == 0 ? null : (
                <div>
                  <div>
                    <input
                      type='Search'
                      className='filtering-search-tag'
                      placeholder='Search By Username And Specialist'
                      autoFocus
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      height:
                        rows.length > 20
                          ? 1000
                          : rows.length > 8
                            ? 400
                            : rows.length > 5
                              ? 250
                              : 200,
                      width: '100%',
                    }}
                  >
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pageSize={20}
                      rowsPerPageOptions={[10]}
                      checkboxSelection={checkboxSelection}
                      onSelectionModelChange={ids => {
                        const selectedIDs = new Set(ids)
                        const selectedRowData = rows.filter(row =>
                          selectedIDs.has(row.id)
                        )
                        let x = []

                        setFcmtokens(selectedRowData)

                        for (let i = 0; i < selectedRowData.length; i++) {
                          if (selectedRowData[i].fcmtoken == null) {
                            console.log('nill')
                          } else {
                            x.push(selectedRowData[i].fcmtoken)
                          }
                        }

                        if (x.length == 0) {
                          setshowmodal(false)
                        } else {
                          setshowmodal(true)
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* CALLS AND EMERGENCY MESSAGES */}
          <div class="body-section">
            {/* CALL BUTTONS SECTION */}
            <div className='main-buttons-container'>
              <h1>To initiate a call or an urgent call, select one or multiple recipients then click on one of the buttons below.</h1>
              <div className="buttons-container">
                {!showmodal ? (
                  <div>
                    <button
                      disabled
                      class="call-button"
                    >
                      Call
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      onClick={() => multiple()}
                      class="call-button"
                      style={{ cursor: "pointer", opacity: 1 }}
                    >
                      Call
                    </button>
                  </div>
                )}

                {!showmodal ? (
                  <div>
                    <button
                      disabled
                      className='call-button'
                    >
                      Call Urgent
                    </button>
                  </div>
                ) : (
                  <div style={{ margin: 5 }}>
                    <button
                      onClick={() => multipleurgent()}
                      className='call-button'
                      style={{ cursor: "pointer", opacity: 1 }}
                    >
                      Call Urgent
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* EMERGENCY SECTION */}
            <div className="emergency-container">
              <h1>To initiate an Emergency Broadcast, provide the necessary details in the form below.</h1>
              <div className='emergency-form'>
                <div>
                  {/* <label>Tile:</label> */}
                  <input
                    type='title'
                    placeholder='Enter title'
                    autoFocus
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                    className='emergency-input'
                  />
                </div>
                <div>
                  {/* <label>Message:</label> */}
                  <textarea
                    placeholder='Write a message'
                    value={body}
                    onChange={e => setBody(e.target.value)}
                    cols='40'
                    rows='5'
                    className='emergency-input'
                  ></textarea>
                </div>
              </div>
              <div className='emergency-buttons-container'>
                {!showemergencybutton ? (
                  <div>
                    <button
                      disabled
                      class='emergency-button'
                    >
                      Emergency Broadcast
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      onClick={() => multiple3()}
                      class='emergency-button'
                    >
                      Emergency Broadcast
                    </button>
                  </div>
                )}
                {!showemergencybutton ? (
                  <div>
                    <button
                      disabled
                      class='emergency-button'
                    >
                      Emergency Broadcast to Selected Recipients
                    </button>
                  </div>
                ) : (
                  <div>
                    <button
                      onClick={() => sendPushEmergencySelectedrecipients()}
                      class='emergency-button'
                    >
                      Emergency Broadcast to Selected Recipients
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* REPLIES SECTION */}
        <div className="replies-section" id="repliesSection">
          <h1>REPLIES</h1>
          <div id="dynamicReplies"></div>
          <div className='view-more-bttn'>
            {/* <button>View All Replies</button> */}
          </div>
        </div>
      </div>

      {/* POPUP MODALS */}
      {showModal ? (
        <Modal
          isOpen={showModal}
          contentLabel="Message Notification"
          className="message-modal"
        >
          <div class="modal-content">
            <h2>Message Sent</h2>
            <p>Message sent to {total} , {success} success , {failure} failure</p>
            <div>
              <button onClick={() => { setShowModal(false); window.location.reload(false); }} className="ok-button">OK</button>
            </div>
          </div>
        </Modal>
      ) : null}

      {
        showModalCheck ? (
          <Modal
            isOpen={showModalCheck}
            contentLabel="Message Confirmation"
            className="message-modal"
          >
            <div className="modal-content">
              {showMessageSent ? (
                <div>
                  <h2>Your message is sent.</h2>
                  <button className="ok-button" onClick={() => { setShowModalCheck(false); window.location.reload(false); }}>OK</button>
                </div>
              ) : showMessageCancelled ? (
                <div>
                  <h2>The operation is canceled.</h2>
                  <button className="ok-button" onClick={() => { setShowModalCheck(false); window.location.reload(false); }}>OK</button>
                </div>
              ) : (
                <div>
                  <h2>Message Confirmation</h2>
                  <p>You're about to send the notification call.</p>
                  <p>Would you like to proceed?</p>
                  <div className="modal-buttons">
                    <div className="proceed-bttn-container">
                      <button className="proceed-button" onClick={() => { senpushnotificationcall(); setShowMessageSent(true); }}>Yes</button>
                    </div>
                    <div className="cancel-bttn-container">
                      <button className="cancel-button" onClick={() => { setShowMessageCancelled(true); }}>Cancel</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        ) : null
      }

      {
        showSelectOption ? (
          <Modal
            isOpen={showSelectOption}
            className="message-modal"
          >
            <div className="modal-content">
              <div class="close-button" onClick={() => {
                setShowSelectOption(false);
              }} >
                <img src={closeButton} />
              </div>
              <h1>Select Groups</h1>
              <div className="grp-sect-container grp-select-option">
                {/* <h1>Select By Group</h1> */}
                {isgroup ? (
                  <div className="allGroups-container">
                    {group.map(option => (
                      <label key={option.Group_id}>
                        <input
                          type="checkbox"
                          value={option.Group_id}
                          defaultChecked={groupid.includes(option.Group_id)}
                          onChange={handleGroupChange}
                        />
                        {option.Group_name}
                      </label>
                    ))}
                  </div>
                ) : null}
              </div>
              <div>
                <button class="select-options-button" onClick={() => sendpushnotificationselectecipients()}>Send Broadcast</button>
              </div>
            </div>
          </Modal>
        ) : null
      }

      {
        showModalCheckUrgent ? (
          <Modal
            isOpen={showModalCheckUrgent}
            contentLabel="Message Confirmation"
            className="message-modal"
          >
            <div className="modal-content">
              {showMessageSent ? (
                <div>
                  <h2>Your message is sent.</h2>
                  <button className="ok-button" onClick={() => { setShowModalCheckUrgent(false); window.location.reload(false); }}>OK</button>
                </div>
              ) : showMessageCancelled ? (
                <div>
                  <h2>The operation is canceled.</h2>
                  <button className="ok-button" onClick={() => { setShowModalCheckUrgent(false); window.location.reload(false); }}>OK</button>
                </div>
              ) : (
                <div>
                  <h2>Message Confirmation</h2>
                  <p>You're about to send the notification call.</p>
                  <p>Would you like to proceed?</p>
                  <div className="modal-buttons">
                    <div className="proceed-bttn-container">
                      <button className="proceed-button" onClick={() => { senpushnotificationcallurgent(); setShowMessageSent(true); }}>Yes</button>
                    </div>
                    <div className="cancel-bttn-container">
                      <button className="cancel-button" onClick={() => { setShowMessageCancelled(true); }}>Cancel</button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        ) : null
      }
    </div >
  )
}

