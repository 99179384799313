
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig  ={
    apiKey: "AIzaSyBK9muhlLXBoLDjz5-AuqzX5nfY4qqIFYg",
    authDomain: "pager-f5abc.firebaseapp.com",
    projectId: "pager-f5abc",
    storageBucket: "pager-f5abc.appspot.com",
    messagingSenderId: "654538647805",
    appId: "1:654538647805:web:a468f3435d61c0ef79b694"
}


const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
export default app;
