import React, { useState, useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import logo from '../Images/logo.png'

import Button from 'react-bootstrap/Button'

export default function Login () {
  const navigate = useNavigate()
  const [data, setData] = useState('savio local storage')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [checked, setChecked] = useState(false)
  function validateForm () {
    return email.length > 0 && password.length > 0
  }

  const getApiData = async () => {
    const response = await fetch('https://pager.osinode.com/auth/userbyID/4')
      .then(response => response.json())
      .then(data => {
        console.log(data)
      })
      .catch(error => {})

    // update the state
  }

  const handleSubmit = async () => {
    fetch('https://pager.osinode.com/auth/login/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8'
      },
      body: JSON.stringify({
        email: '1@1.com',
        password: password,
        username: email
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.status == 'True' && data.is_Admin == 'False') {
          alert('you are not an admin')
        }
        if (data.status == 'True' && data.is_Admin == 'True') {
          if (checked) {
            localStorage.setItem('isloggedin', 'true')
            localStorage.setItem('userid', data.id)
            localStorage.setItem('username', data.firstname)
            localStorage.setItem('ext', data.ext)
            navigate('/Home', {
              state: {
                userid: data.id,
                username: data.firstname,
                ext: data.ext
              }
            })
          } else {
            localStorage.setItem('isloggedin', 'false')
            navigate('/Home', {
              state: {
                userid: data.id,
                username: data.firstname,
                ext: data.ext
              }
            })
          }
        } else {
          alert(data.detail)
        }
      })
      .catch(error => {
        console.log(error)
      })
    //   const response = await fetch("https://pager.osinode.com/auth/login/", {
    //     method: 'POST',

    //        credentials: 'include',
    //        crossorigin: true,
    //        mode: 'no-cors',
    //     headers: {

    //       'Accept':  'application/json',
    //       'Content-Type': 'application/json',

    //     },
    //    body: JSON.stringify({
    //        email:email,
    //       // Notification_Body:body,

    //   })

    // }).then(response => response.json())
    //      .then(data => {
    // if(data.status=="True" && data.is_Admin=="False"){
    //  alert('you are not an admin')
    // }if(data.status=="True"&&data.is_Admin=="True"){

    //  navigate('/home')
    // }else{

    // }

    //  })
    // .catch((error) => {console.log(error)})

    //     await fetch("https://pager.osinode.com/auth/login/", {
    //       method: 'POST',
    //        credentials: 'include',
    //        crossorigin: true,
    //        mode: 'no-cors',
    //        headers: {
    //         'Access-Control-Allow-Origin': '*',
    //           'Accept': 'application/json',
    //           'Content-Type': 'application/json',
    //        },
    //       //  body: JSON.stringify({
    //       //     email:email,
    //       //     password:password,
    //       //  })

    // body:{
    //   "email":"savio.khoury3@gmail.com",
    //   "password":"789512357Sp"
    // }

    //      }).then(response => response.json())
    //      .then(data => {
    // console.log(data)

    //      })
    //     .catch((error) => {})
  }
  useEffect(() => {
    // alert(localStorage.getItem('isloggedin'))
    if (localStorage.getItem('isloggedin') == 'true') {
      navigate('/Home', {
        state: {
          userid: localStorage.getItem('userid'),
          username: localStorage.getItem('username'),
          ext: localStorage.getItem('ext')
        }
      })
    }else{
      console.log('test')
    }
  }, [])
  return (
    <div>
      <div className='Auth-form-container'>
        <div className='Auth-form-content'>
          <img src={logo} alt='my-logo' />
          <h3 className='Auth-form-title'>Sign In</h3>
          <div className='form-group mt-3'>
            <label>UserName</label>
            <input
              type='email'
              className='form-control mt-1'
              placeholder='Enter Username'
              autoFocus
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div className='form-group mt-3'>
            <label>Password</label>
            <input
              type='password'
              className='form-control mt-1'
              placeholder='Enter password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>

          <input
            type='checkbox'
            defaultChecked={checked}
            onChange={() => {
              setChecked(!checked)
            }}
          />
          <label for='vehicle1'> Keep Me Logged In</label>
          <div className='d-grid gap-2 mt-3'>
            <button onClick={() => handleSubmit()} className='btn btn-primary'>
              Submit
            </button>
          </div>
        </div>

        {/* {users &&
        users.map((user) => (
          <div className="item-container">
            Id:{user.id} <div className="title">Title:{user.username}</div>
          </div>
        ))} */}

        {/* <Button onClick={()=>getApiData()}>press mee</Button> */}
      </div>
    </div>
  )
}
